<template>
  <div>
    <v-container v-if="userData.role === 'CUST'" fluid class="px-3 px-lg-0">
      <v-row>
        <v-col cols="12" md="4" lg="3">
          <v-row>
            <v-col sm="6" md="12">
              <v-card class="pa-5 pb-6 pa-md-0 pb-md-0" rounded="lg">
                <div v-if="!$vuetify.breakpoint.smAndDown" class="text-center"></div>

                <v-list two-line>
                  <v-subheader> Firma Bilgileriniz</v-subheader>
                  <v-list-item>
                    <v-list-item-avatar color="nightDark">
                      <v-avatar>
                        <v-icon :size="24">
                          {{ icons.mdiDomain }}
                        </v-icon>
                      </v-avatar>
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-subtitle>Cari Unvan</v-list-item-subtitle>
                      <v-list-item-title>{{ userData.company }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-avatar color="nightDark">
                      <v-avatar>
                        <v-icon :size="24">
                          {{ icons.mdiCodeBrackets }}
                        </v-icon>
                      </v-avatar>
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-subtitle>Müşteri Numarası</v-list-item-subtitle>
                      <v-list-item-title>{{ userData.cari_kod }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="my-3" />
                  <v-subheader> Satış Temsilciniz</v-subheader>
                  <v-list-item>
                    <v-list-item-avatar color="nightDark">
                      <v-avatar>
                        <v-icon :size="24">
                          {{ icons.mdiAccount }}
                        </v-icon>
                      </v-avatar>
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-subtitle>Adı Soyadı</v-list-item-subtitle>
                      <v-list-item-title>{{ userData.saldept_name }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider inset></v-divider>
                  <v-list-item>
                    <v-list-item-avatar color="nightDark">
                      <v-avatar>
                        <v-icon :size="24">
                          {{ icons.mdiPhone }}
                        </v-icon>
                      </v-avatar>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-subtitle>Cep Telefonu</v-list-item-subtitle>
                      <v-list-item-title>{{ userData.saldept_phone }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

                  <v-divider inset></v-divider>

                  <v-list-item class="pb-5">
                    <v-list-item-avatar color="nightDark">
                      <v-avatar>
                        <v-icon :size="24">
                          {{ icons.mdiAt }}
                        </v-icon>
                      </v-avatar>
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-subtitle>E-Posta</v-list-item-subtitle>
                      <v-list-item-title>{{ userData.saldept_email }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" md="8" lg="9">
          <v-row>
            <v-col cols="12" lg="7">
              <v-row style="height: calc(100% + 24px)">
                <v-col cols="12" sm="6">
                  <v-card color="grey" rounded="lg" height="100%" class="d-flex align-center py-3">
                    <div class="d-flex">
                      <v-avatar class="mx-5" color="secondary">
                        <v-icon color="white" size="24">
                          {{ icons.mdiPodium }}
                        </v-icon>
                      </v-avatar>
                      <div>
                        <div class="text-caption font-weight-light">Bu Ayki Toplam</div>
                        <div class="text-body-1 black--text font-weight-medium pb-5">Sipariş Tutarı</div>
                        <v-chip outlined color="primary" label>
                          {{ toplamSiparisTutari | currency }}
                        </v-chip>
                      </div>
                    </div>
                  </v-card>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-card color="grey" rounded="lg" height="100%" class="d-flex align-center py-3">
                    <div class="d-flex">
                      <v-avatar class="mx-5" color="secondary">
                        <v-icon color="white" size="24">
                          {{ icons.mdiCartArrowDown }}
                        </v-icon>
                      </v-avatar>
                      <div>
                        <div class="text-caption font-weight-light">Bu Ayki Toplam</div>
                        <div class="text-body-1 black--text font-weight-medium pb-5">Sipariş Adedi</div>
                        <v-chip outlined color="primary" label> 12 Adet </v-chip>
                      </div>
                    </div>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" lg="5">
              <v-card rounded="lg" height="100%">
                <v-simple-table dense>
                  <template v-slot:default>
                    <tbody>
                      <tr>
                        <td class="text-body-2 py-2">Borç</td>
                        <td class="text-body-2 py-2">
                          <span class="warning--text"> : {{ balance.BORC | currency }}</span>
                        </td>
                      </tr>
                      <tr>
                        <td class="text-body-2 py-2">Alacak</td>
                        <td class="text-body-2 py-2">
                          <span class="success--text"> : {{ balance.ALACAK | currency }}</span>
                        </td>
                      </tr>
                      <tr>
                        <td class="text-body-2 py-2">Bakiye</td>
                        <td class="text-body-2 py-2">
                          <span class="error--text"> : {{ balance.BAKIYE | currency }}</span>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card>
            </v-col>
          </v-row>

          <v-row v-if="userData.role != 'CUST'">
            <v-col cols="12">
              <v-card>
                <v-card-title>
                  <v-avatar color="accent">
                    <v-icon :size="24" color="white">
                      {{ icons.mdiStar }}
                    </v-icon>
                  </v-avatar>
                  <span class="ml-5 primary--text">
                    <div class="text-caption">Son 1 Yıl İçinde</div>
                    <div class="text-body-1 white--text font-weight-medium">En Çok Satın Aldığınız Ürünler</div>
                  </span>
                  <v-spacer />
                  <v-btn v-if="$vuetify.breakpoint.smAndUp" color="accent" depressed small to="order-tracking">
                    Tüm Siparişleriniz
                  </v-btn>
                </v-card-title>

                <v-data-table
                  :headers="headers"
                  :items="toSipAdetiRec"
                  :items-per-page="10"
                  hide-default-footer
                ></v-data-table>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="8">
          <span class="text-caption">2024 © Tukas</span>
        </v-col>
        <v-col cols="12" md="4" class="text-right">
          <v-chip small label> v1.0.1 </v-chip>
        </v-col>
      </v-row>
    </v-container>


    <v-container v-if="userData.role === 'PLSYR'" fluid class="px-3 px-lg-0">
      <v-row>
        <v-col cols="12" md="4" lg="3">
          <v-row style="height: 200px">
            <v-col sm="6" md="12" >
              <v-card class="pa-5 pb-6 pa-md-0 pb-md-0" rounded="lg" height="100%" >
                <div v-if="!$vuetify.breakpoint.smAndDown" class="text-center">
                  <img
                  :src="isDark ? textLogoDark : textLogo"
                  height="50"
                  class="pa"
                />                
                </div>   

                <div class="font-weight-bold text-center text-body-1 black--text font-weight-medium pb-5"
                   :class="{ 'white--text': isDark, 'black--text': !isDark }"
                 >
                  {{ userData.saldept_name }}
                </div>  

                <div class="font-weight-bold text-center text-body-1 black--text font-weight-medium pb-5" >
                <v-select
                v-model="quarterDeger"                
                    placeholder="Çeyrek Seçiniz"                   
                    hide-details=""
                    :items="quarterItems"
                    filled
                    class="rounded-0 elevation-0"
                    attach                   
                    @change="kondisyonChange"
                  ></v-select>
                </div>  
              </v-card>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" md="8" lg="9">
          <v-row>
            <v-col cols="12" lg="12">
              <v-row v-if="$vuetify.breakpoint.xsOnly" style="height: 450px">
                <v-col cols="12" sm="4">
                  <v-card color="grey" rounded="lg" height="100%" class="d-flex align-center py-3">
                    <div class="d-flex">
                      <v-avatar class="mx-5" color="secondary">
                        <v-icon color="white" size="24">
                          {{ icons.mdiPodium }}
                        </v-icon>
                      </v-avatar>
                      <div>
                        <div class="text-caption font-weight-light">Bu Ayki Toplam</div>
                        <div class="text-body-1 black--text font-weight-medium pb-5"
                           :class="{ 'white--text': isDark, 'black--text': !isDark }"
                        >Ciro</div>
                        <v-chip outlined color="primary" label>
                          {{ ciro | currency }}
                        </v-chip>
                      </div>
                    </div>
                  </v-card>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-card color="grey" rounded="lg" height="100%" class="d-flex align-center py-3">
                    <div class="d-flex">
                      <v-avatar class="mx-5" color="secondary">
                        <v-icon color="white" size="24">
                          {{ icons.mdiCartArrowDown }}
                        </v-icon>
                      </v-avatar>
                      <div>
                        <div class="text-caption font-weight-light">Bu Ayki Toplam</div>
                        <div class="text-body-1 black--text font-weight-medium pb-5"
                         :class="{ 'white--text': isDark, 'black--text': !isDark }"
                        >
                          Satış Tonajı
                        </div>
                        <v-chip outlined color="primary" label> {{ Number(this.tonaj).toLocaleString() || 0  }} Ton </v-chip>
                      </div>
                    </div>
                  </v-card>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-card color="grey" rounded="lg" height="100%" class="d-flex align-center py-3">
                    <div class="d-flex">
                      <v-avatar class="mx-5" color="secondary">
                        <v-icon color="white" size="24">
                          {{ icons.mdiAccountGroup }}
                        </v-icon>
                      </v-avatar>
                      <div>
                        <div class="text-caption font-weight-light">Bu Yılki Toplam </div>
                        <div class="text-body-1 black--text font-weight-medium pb-5"
                           :class="{ 'white--text': isDark, 'black--text': !isDark }"
                        >Müşteri Nokta Sayısı</div>
                        <v-chip outlined color="primary" label> {{ must_nokta_sayisi }} Adet </v-chip>
                      </div>
                    </div>
                  </v-card>
                </v-col>
              </v-row>

              <v-row v-else style="height: 200px">
                <v-col cols="12" sm="4">
                  <v-card color="grey" rounded="lg" height="100%" class="d-flex align-center py-3">
                    <div class="d-flex">
                      <v-avatar class="mx-5" color="secondary">
                        <v-icon color="white" size="24">
                          {{ icons.mdiPodium }}
                        </v-icon>
                      </v-avatar>
                      <div>
                        <div class="text-caption font-weight-light">Bu Ayki Toplam</div>
                        <div class="text-body-1 black--text font-weight-medium pb-5"
                           :class="{ 'white--text': isDark, 'black--text': !isDark }"
                        >Ciro</div>
                        <v-chip outlined color="primary" label>
                          {{ ciro | currency }}
                        </v-chip>
                      </div>
                    </div>
                  </v-card>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-card color="grey" rounded="lg" height="100%" class="d-flex align-center py-3">
                    <div class="d-flex">
                      <v-avatar class="mx-5" color="secondary">
                        <v-icon color="white" size="24">
                          {{ icons.mdiCartArrowDown }}
                        </v-icon>
                      </v-avatar>
                      <div>
                        <div class="text-caption font-weight-light">Bu Ayki Toplam</div>
                        <div class="text-body-1 black--text font-weight-medium pb-5"
                         :class="{ 'white--text': isDark, 'black--text': !isDark }"
                        >Satış Tonajı</div>
                        <v-chip outlined color="primary" label> {{ Number(this.tonaj).toLocaleString() || 0 }} Ton </v-chip>
                      </div>
                    </div>
                  </v-card>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-card color="grey" rounded="lg" height="100%" class="d-flex align-center py-3">
                    <div class="d-flex">
                      <v-avatar class="mx-5" color="secondary">
                        <v-icon color="white" size="24">
                          {{ icons.mdiAccountGroup }}
                        </v-icon>
                      </v-avatar>
                      <div>
                        <div class="text-caption font-weight-light">Bu Yılki Toplam </div>
                        <div class="text-body-1 black--text font-weight-medium pb-5"
                           :class="{ 'white--text': isDark, 'black--text': !isDark }"
                        >Müşteri Nokta Sayısı</div>
                        <v-chip outlined color="primary" label> {{ must_nokta_sayisi }} Adet </v-chip>
                      </div>
                    </div>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>         
          </v-row>      
        </v-col>
      </v-row>

  <!--     <v-row>
        <v-col
          cols="12"
          md="6"
        >  
           <StatisticsCardBarChart  width="100" :chartSeries=dataBarSeriesTonaj1 
           :chartCategories=dataBarLabelsTonaj :statistics=dataTitleTonaj> 
        
          </StatisticsCardBarChart>
                   
        </v-col>
        <v-col
          cols="12"
          md="6"     
        >       
        <StatisticsCardBarChart  width="100" :chartSeries=dataBarSeriesCiro1
         :chartCategories=dataBarLabelsCiro  :statistics=dataTitleCiro> </StatisticsCardBarChart>
        </v-col>
      </v-row>

      <v-row>
        <v-col
          cols="12"
          md="4"
        >      
        <StatisticsCardPieChart width="100" :chartSeries="dataPieSeries1" :statistics="dataTitle1"  :chartLabels="dataPieLabels1"> </StatisticsCardPieChart>  
      </v-col>
        <v-col
          cols="12"
          md="4"     
        >
        <StatisticsCardPieChart width="100" :chartSeries="dataPieSeries2" :statistics="dataTitle2" :chartLabels="dataPieLabels2"> </StatisticsCardPieChart>
        </v-col>
        <v-col
        cols="12"
        md="4"     
      >
      <StatisticsCardPieChart width="100" :chartSeries="dataPieSeries3"  :statistics="dataTitle3" :chartLabels="dataPieLabels3"> </StatisticsCardPieChart>
      </v-col>
      </v-row> -->

      
      <v-row>
        <v-col cols="12" md="6" sm="12">
          <StatisticsCardBarChart width="100" :chartSeries="dataBarSeriesTonaj1" :chartCategories="dataBarLabelsTonaj" :statistics="dataTitleTonaj" />
        </v-col>
        <v-col cols="12" md="6" sm="12">
          <StatisticsCardBarChart width="100" :chartSeries="dataBarSeriesCiro1" :chartCategories="dataBarLabelsCiro" :statistics="dataTitleCiro" />
        </v-col>
      </v-row>
        

      <v-row>
        <v-col cols="12" md="4" sm="6" >
          <StatisticsCardPieChart class="statistics-card" width="100" :chartSeries="dataPieSeries1" :statistics="dataTitle1" :chartLabels="dataPieLabels1" />
        </v-col>
        <v-col cols="12" md="4" sm="6">
          <StatisticsCardPieChart class="statistics-card" width="100" :chartSeries="dataPieSeries2" :statistics="dataTitle2" :chartLabels="dataPieLabels2" />
        </v-col>
        <v-col cols="12" md="4" sm="6">
          <StatisticsCardPieChart class="statistics-card" width="100" :chartSeries="dataPieSeries3" :statistics="dataTitle3" :chartLabels="dataPieLabels3" />
        </v-col>
      </v-row>
      
    
    </v-container>

  <!--   <div>
      <v-snackbars
      :messages.sync="snackbar"
      width="600"
      color="counter"      
      bottom
      right
      transition="slide-y-reverse-transition"
    >
    <template v-slot:default="{ message }">
      <div class="d-flex flex-column align-center">
        <div class="text-center">
          <div class="text-h6 font-weight-bold " style="color: black;">
            {{ message.title }}
          </div>
          <div class="text-body-1" style="color: black;">
            {{ message.content }}
          </div>
        </div>
        <v-img
        v-if="message.imageSrc" 
        :src="message.imageSrc"       
        width="calc(100 - 4px)" 
        height="auto" 
        class="mt-2"
        style="object-fit: cover; margin: 2px;" 
      ></v-img>
      </div>
    </template>
      <template v-slot:action="{ close }">
        <v-btn
          icon
          small
          @click="close()"
        >
          <v-icon small>
            {{ icons.mdiClose }}
          </v-icon>
        </v-btn>
      </template>
    </v-snackbars>   
    </div> -->

    <v-dialog v-model="dialogForm" :overlay-opacity="0.9" persistent width="500" max-height="300">
      <v-card color="nightDark">
        <v-card-title>
          <v-icon left large>
            {{ icons.mdiDraw }}
          </v-icon>
          <span class="headline">{{ messageDialog.title }}</span>
          <v-spacer />
          <v-btn icon  @click="dialogForm = false">
            <v-icon>{{ icons.mdiClose }}</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <div class="text-body-1">{{ messageDialog.content }}</div>
          <v-img
            v-if="messageDialog.imageSrc"
            :src="messageDialog.imageSrc"
            width="100%"
            max-height="400" 
            class="mt-2"
            style="object-fit: contain;"
          ></v-img>
        </v-card-text>
      
      </v-card>
    </v-dialog>
   
  </div>
</template>

<script>
import { useRouter } from '@core/utils'
import { getCurrentInstance, onMounted, ref ,computed} from '@vue/composition-api'
import StatisticsCardBarChart from '@core/components/statistics-card/StatisticsCardBarChart.vue'
import StatisticsCardPieChart from '@core/components/statistics-card/StatisticsCardPieChart.vue'
import Vue from 'vue'
import useAppConfig from '@core/@app-config/useAppConfig'
import themeConfig from '@themeConfig'
  import VSnackbars from 'v-snackbars'
import {
  mdiAccount,
  mdiAt,
  mdiCalendar,
  mdiCalendarClock,
  mdiCartArrowDown,
  mdiChevronLeft,
  mdiCodeBrackets,
  mdiDomain,
  mdiFilter,
  mdiIp,
  mdiLogin,
  mdiPhone,
  mdiPodium,
  mdiSend,
  mdiStar,
  mdiTrayFull,
  mdiAccountGroup,
  mdiClose
} from '@mdi/js'

import { initialAbility } from '@/plugins/acl/config'
import store from '@/store'

export default {
  components: {
    StatisticsCardBarChart,
    StatisticsCardPieChart,
    VSnackbars,
  },
  setup() {
    // UnRegister on leave
    const dialogForm = ref(false)
    const snackbar = ref([])
    const messageDialog = ref([])
    const quarterDeger = ref(1)
    const quarterItems = ref([])
   
    const { isDark } = useAppConfig()

    const userData = JSON.parse(localStorage.getItem('userData'))
    const isLoggedIn = () => {
      return localStorage.getItem('isLoggedIn') === 'true';
    };

    const vm = getCurrentInstance()?.proxy
    const { router } = useRouter()

    const logoutUser = () => {
    
      // Remove userData from localStorage
      // ? We just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem('accessToken')

      // Remove userData & Ability from localStorage
      localStorage.removeItem('userData')
      localStorage.removeItem('userAbility')
      localStorage.removeItem('isLoggedIn');
      localStorage.setItem('messageShown', 'false');
      // Reset ability
      vm.$ability.update(initialAbility)

      // Redirect to login page
      router.push({ name: 'auth-login' })
    }

    const toSipAdetiRec = ref([])
    const headers = ref([
      {
        text: 'Ürün Kodu',
        align: 'start',
        sortable: false,
        value: 'prd_code',
        width: '150',
      },
      { text: 'Ürün Adı', value: 'baslik', sortable: false },
      {
        text: 'Sipariş Adet',
        value: 'topsipadeti',
        align: 'right',
        sortable: false,
        width: '150',
      },
    ])
    const toplamSiparisTutari = ref(0)
    const sonuc = ref({ create_date: '', last_login: '' })

    const ciro = ref(0)
    const tonaj = ref(0)
    const must_nokta_sayisi = ref(0)
    const dataListPieTable = ref([])
  
    onMounted(() => {
      fetchDashboard()
      fetchSalDeptDashboard()      
      if (userData.role === 'PLSYR'){
      fetchQuarterListItems()
      }

  // Daha önce mesaj gösterilmişse, fetchMessage çağırma
  debugger
     const messageShown = localStorage.getItem('messageShown');
     if (isLoggedIn()) {
        if (messageShown !== 'true') {
          fetchMessage();
        } 
      } 
      
    })
    

    const fetchQuarterListItems =()=>
    {
      const param = {
        method: 'getQuarterList',
        year:2024
      }
      store
        .dispatch('postMethod', param)
        .then(response => {
          if (!response.error) {          
          const data = response.response
          quarterItems.value = data.map(key => ({ text: key.title, value: key.quarter }))
          }
         else {
          console.table('hatalı =>', response)
          }
        })
        .catch(error => {
          console.log(error)
        })
    }    
    const balance = ref({})
    const fetchDashboard = () => {
      const method = 'getCustomerDashboardVars'

      store.dispatch('postMethod', { method }).then(response => {
        if (response.error == '0') {
          toSipAdetiRec.value = response.response.toSipAdetiRec
          sonuc.value = response.response.sonuc
          toplamSiparisTutari.value = response.response.toplamSiparisTutari

          balance.value = response.response.balance
        } else {
          logoutUser()
        }
      })
    }
    const fetchSalDeptDashboard = () => {
      
      const method = 'getSaldeptDashboardData'

      store.dispatch('postMethod', { method }).then(response => {
        if (response.error == '0') {
          ciro.value = response.detail.ciro
          tonaj.value = response.detail.tonaj
          must_nokta_sayisi.value = response.detail.mns

        } else {
          logoutUser()
        }
      })
    }
    function isEmpty(obj) {
      return Object.keys(obj).length === 0;
    }
    const fetchMessage = async () => {
      /* if (!localStorage.getItem('messageShown')) { */
        // API çağrısı
        debugger
      const response = await store.dispatch('postMethod', {
        method: 'getOpenerMsg'
      });

      if (response && response.response) {
        const res = response.response;
        if (!isEmpty(res)) {
          messageDialog.value = {
            content: res.content || 'Varsayılan İçerik',
            title: res.stext || 'Varsayılan Başlık',
            imageSrc: "https://images.unsplash.com/photo-1506748686214-e9df14d4d9d0",
          };
debugger
          // Mesaj ID'sini al
          const messageId = res.id; // ID'yi alın

          // getMediaItems API'sini çağır
          const mediaResponse = await fetchMediaItems(messageId);
          if (mediaResponse && mediaResponse.error === 0) {
              const imagePaths = mediaResponse.files.bresim.map(file => file.dosya_adi);
              console.log("Dosya yolları:", imagePaths); // Dosya yollarını konsola yazdır

              // İlk resmi dialogda göster
              if (imagePaths.length > 0) {
                  messageDialog.value.imageSrc = 'https://portalapi.tukas.com.tr/' + imagePaths[0]; // İlk resmi kullan
                 
              }

          } else {
              console.error('Media API hatası:', mediaResponse.msg);
          }


             // Mesaj gösterildi olarak işaretle
         localStorage.setItem('messageShown', 'true');
         dialogForm.value = true
                
        } else {
          console.log('response.response boş.');
        }    
      
      } 
    };

        // Media dosyalarını almak için fonksiyon
      const fetchMediaItems = async (messageId) => {
      const localid = messageId;
      const modul = 'OpenerMsg';
        const method = 'getMediaItems';

        try {
            const response = await store.dispatch('postMethod', { localid, modul,method });
            return response; // Yanıtı döndür
        } catch (error) {
            console.error('Fetch hatası:', error);
            return null; // Hata durumunda null döndür
        }
    };

    const dialogClose = () => {
      debugger
      loading.value = false
      dialogForm.value = false
    }

    return {
    
      userData,
      toSipAdetiRec,
      headers,
      sonuc,
      toplamSiparisTutari,
      balance,

      ciro,
      tonaj,
      must_nokta_sayisi,
      quarterItems,
      quarterDeger,
      fetchMessage,
      snackbar,      
      dialogForm,
      messageDialog,
      dialogClose,

      isDark,     
      textLogo: themeConfig.app.textLogo,
      textLogoDark: themeConfig.app.textLogoDark,
      // icons
      icons: {
        mdiCartArrowDown,
        mdiPodium,
        mdiIp,
        mdiLogin,
        mdiStar,
        mdiTrayFull,
        mdiFilter,
        mdiSend,
        mdiCalendarClock,
        mdiCalendar,
        mdiChevronLeft,
        mdiAt,
        mdiPhone,
        mdiAccount,
        mdiCodeBrackets,
        mdiDomain,
        mdiAccountGroup,
        mdiClose
      },
    }
  },

  mounted() {      
    if(this.quarterDeger!== null)
    {   
      if (this.userData.role === 'PLSYR'){
      this.fetchChartOptions(this.quarterDeger)
      this.fetchBarChartOptions(this.quarterDeger) 
    }  
    }
    
    // Sekme kapatıldığında localStorage'daki messageShown değerini false yap
    debugger
    window.addEventListener('beforeunload', () => {
      localStorage.setItem('messageShown', 'false');
    });
  },
  beforeDestroy() {
    debugger
    // Temizlik yap
    window.removeEventListener('beforeunload', () => {
      localStorage.setItem('messageShown', 'false');
    });
  },
  methods: {
   async fetchChartOptions(val){
      const chartOptions = {
      chart: {
        id: "apexchart-example",     
        type: 'pie',
        height: '350px',      
      
      },
      title: {
        text: "",
        align: "center",
        margin: 40,
        style: {
          fontSize: "20px",
          fontWeight: "bold",
          color: "#263238",
        },
      },
     
      labels:["Apple", "Mango", "Orange", "Watermelon"],   
      legend: {
        show: true,
        position: 'bottom',
      },
      dataLabels: {
        style: {
        fontSize: '12px',
        fontWeight: 'bold',
      },
      background: {
        enabled: false,
        foreColor: '#483E3E',
        borderRadius: 2,
        padding: 4,
        opacity: 0.9,
        borderWidth: 1,
        borderColor: '#2E93fA'
        
      },
      
  
        enabled: true,        
        formatter: function (value) {
          return Math.round(value) + '%';
        },
        font: {
          weight: 'bold',
          size: 16,
        }
        
      },
      colors: ['#2E93fA', '#46DA62', '#242E8A', '#E91E63', '#FF9800'],
   
      responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 400
            },
            legend: {
              position: 'bottom'
            }
          }
        }]
    }    
    
      /* const query = {
        quarter: val
      }
      const method = 'getQuarterBasedSales'
      store
      .dispatch('fetchMethod', { query, method }) */

      store
            .dispatch('postMethod', {
              method: 'getQuarterBasedSales',
              quarter: val
            })
        .then(response => {
          if (!response.error) {          
          const data=  response.detail[0]
          const data2 = response.detail[1];
          const data3 = response.detail[2];
          this.dataPieLabels1=data.map(item=>item.key)
          this.dataPieSeries1=data.map(item=>item.value)
          this.dataTitle1=response.detail[0][0].title

          this.dataPieLabels2=data2.map(item=>item.key)
          this.dataPieSeries2=data2.map(item=>item.value)
          this.dataTitle2=data2[0].title

          this.dataPieLabels3=data3.map(item=>item.key)
          this.dataPieSeries3=data3.map(item=>item.value)
          this.dataTitle3=data3[0].title


          chartOptions.labels= this.dataPieLabels_2        
          this.chartOptions=chartOptions 
          }
          else {
          console.table('hatalı =>', response)
        }
        })
        .catch(e => {
          Vue.swal({
            title: 'Hata',
            text: e.message,
            width: '350px',
            icon: 'error',
            background: '#FF4C51',
            showCloseButton: false,
            showConfirmButton: true,
            confirmButtonColor: '#cc3d41',
          })
        })   
      
    },
    async fetchBarChartOptions(val){
      const chartOptions = {
      chart: {
        id: "apexchart-example",     
        type: 'pie',
        height: '350px',      
      
      },
      title: {
        text: "",
        align: "center",
        margin: 40,
        style: {
          fontSize: "20px",
          fontWeight: "bold",
          color: "#263238",
        },
      },
     
      labels:["Apple", "Mango", "Orange", "Watermelon"],   
      legend: {
        show: true,
        position: 'bottom',
      },
      dataLabels: {
        style: {
        fontSize: '12px',
        fontWeight: 'bold',

        

      },
      background: {
        enabled: false,
        foreColor: '#483E3E',
        borderRadius: 2,
        padding: 4,
        opacity: 0.9,
        borderWidth: 1,
        borderColor: '#2E93fA'
        
      },
      
  
        enabled: true,        
        formatter: function (value) {
          return Math.round(value) + '%';
        },
        font: {
          weight: 'bold',
          size: 16,
        }
        
      },
      colors: ['#2E93fA', '#46DA62', '#242E8A', '#E91E63', '#FF9800'],
   
      responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom'
            }
          }
        }]
    }     
      
     /*  const query = {
        quarter: val
      }
      const method = 'getYearlyQrtBasedSales'
      store
      .dispatch('fetchMethod', { query, method }) */

      store
            .dispatch('postMethod', {
              method: 'getYearlyQrtBasedSales',
              quarter: val
            })      
        .then(response => {                   
          if (!response.error) {
          const data=  response.detail.TONAJ
          const data2 = response.detail.CIRO;
          this.dataBarLabelsTonaj=data.map(item=>item.title)
          this.dataBarSeriesTonaj=data.map(item=>item.value)

          this.dataBarSeriesTonaj1 = [{
            name: 'Ton',
          
            data: this.dataBarSeriesTonaj.map(value => {
            return new Intl.NumberFormat('tr-TR').format(value);
                  })
            
          }]; 

          /* data.forEach(item => {
            dataBarSeriesTonaj.push( 'name: Ton', 'data:' + item.key)
          })   */ 

          this.dataTitleTonaj="Ağırlık (Ton) - Çeyrek "+val

          this.dataBarLabelsCiro=data2.map(item=>item.title)
          this.dataBarSeriesCiro=data2.map(item=>item.value)
          this.dataTitleCiro="Ciro (TL) - Çeyrek "+val

          this.dataBarSeriesCiro1 = [{
            name: 'Ciro',
            data: this.dataBarSeriesCiro.map(value => {
            return new Intl.NumberFormat('tr-TR').format(value);
                  })
          }]; 


        }
        else {
          console.table('hatalı =>', response)
        }

          //chartOptions.labels= this.dataPieLabels_2        
          //this.chartOptions=chartOptions 
          
        })
        .catch(e => {
          Vue.swal({
            title: 'Hata',
            text: e.message,
            width: '350px',
            icon: 'error',
            background: '#FF4C51',
            showCloseButton: false,
            showConfirmButton: true,
            confirmButtonColor: '#cc3d41',
          })
        })   
      
    },
    kondisyonChange(){     
      
      if (this.userData.role === 'PLSYR'){
        this.fetchChartOptions(this.quarterDeger)
        this.fetchBarChartOptions(this.quarterDeger)
        console.log("quarter",this.quarterDeger)
      }

      }       
  },

  data() {
    return {
      chartOptions: {},
      dataTitle1:"",
      dataPieSeries1: [],
      dataPieLabels1: [],

      dataTitle2:"",
      dataPieSeries2: [],
      dataPieLabels2: [],

      dataTitle3:"",
      dataPieSeries3: [],
      dataPieLabels3: [],

      dataBarLabelsTonaj:[],
      dataBarSeriesTonaj:[],
      dataTitleTonaj:"",
      dataBarSeriesTonaj1:[],

      dataBarLabelsCiro:[],
      dataBarSeriesCiro:[],
      dataTitleCiro:"",
      dataBarSeriesCiro1:[],
    }
  },
}
</script>
<style lang="scss">
@import '@core/preset/preset/apps/user.scss';

.statistics-card {
  max-width: 100%; /* veya %90 */
  margin: 0 auto; /* Merkezleme */
}

@media (max-width: 600px) {
  .statistics-card {
    max-width: 100%; /* veya %90 */
    margin: 0 auto; /* Merkezleme */
    overflow: hidden; /* Taşmaları gizle */
   
  }
  
}
</style>

