<template>
  <v-card class="d-flex flex-column">

    
    <v-card-title class="font-weight-semibold text-center text--primary">
      <v-avatar class="mx-5" color="secondary">
        <v-icon color="white" size="24">
          {{ icons.mdiChartBar }}
        </v-icon>
      </v-avatar>
      {{ statistics }}

    </v-card-title>
    <div v-if="loading" class="d-flex justify-center align-center" style="height: 200px;">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
      <span class="ml-2">Yükleniyor...</span>
    </div>
    <div v-else-if="chartSeries.length  && $vuetify.breakpoint.xsOnly">
    <vue-apex-charts
      id="sessions-chart"
      class="flex-grow-1"
      :options="chartOptionsComputed"
      :series="chartSeries"
      width="400"
      type="bar"
    ></vue-apex-charts> 
   </div>
   <div v-else-if="chartSeries.length">
    <vue-apex-charts
      id="sessions-chart"
      class="flex-grow-1"
      :options="chartOptionsComputed"
      :series="chartSeries"
      width="500"
      type="bar"
    ></vue-apex-charts> 
   </div>

    <v-card-text class="font-weight-semibold text-center text--primary">
      {{ statTitle }}
    </v-card-text>
  </v-card>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import { computed,ref,onMounted } from '@vue/composition-api'


import {
 
  mdiChartBar,
} from '@mdi/js'



export default {
  components: { VueApexCharts },
  props: {
    statistics: { type: String, default: '' },
    statTitle: { type: String, default: '' },
    chartSeries: {      
      type: Array,
      default: () => [],
    },
    chartConfig: {
      type: Object,
      default: null,
    },
    chartCategories: {      
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const loading = ref(true); // Loading durumunu tanımlayın
    const chartOptions = {
      chart: {
        stacked: true,
        type: 'bar',
        height: '350',
        offsetX: -5,
        toolbar: { show: false },
      },
      grid: {
        padding: {
          top: -20,
          bottom: -10,
        },
        yaxis: {
          lines: { show: true },
        },
      },

 
      xaxis: {
        categories:props.chartCategories, // ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep'],
        labels: {
          show: true,
        },
        axisTicks: {
          show: false,
        },
        axisBorder: {
          show: true,
        },
      },
      legend: {
        show: false,
      },
      dataLabels: { 
        enabled: true,
        color:'blue',
        anchor:'end',
        align:'top',
        offset:10,
        formatter: function (value) {
            return new Intl.NumberFormat('tr-TR').format(value) + ' TL'; // TL ekleyin
          },
        
      },
      
      colors: ['#ff9900', '#ff4c51'],
      plotOptions: {
        bar: {
          columnWidth: '50%',
          borderRadius: 10,
          startingShape: 'rounded',
          endingShape: 'rounded',
        },
        distributed: true,
      },
      yaxis: {
        labels: {
          show: false,
        },
      },
      stroke: {
        lineCap: 'round',
        
      },
      
    }

    const chartOptionsComputed = computed(() => {

      if (props.chartCategories.length>0) {        
        chartOptions.xaxis.categories=props.chartCategories 
        const options = JSON.parse(JSON.stringify(chartOptions))
        return options      
      }

      if (props.chartConfig === null) {        
        const options = JSON.parse(JSON.stringify(chartOptions))

        return options
      }

      if (props.chartSeries === null) {
      }
      else{
        console.log(props.chartSeries)
      }


      return props.chartConfig
    })

    onMounted(() => {
      setTimeout(() => {
      loading.value = false; // Yükleme tamamlandığında loading'i false yap
    }, 5000); // 5 saniye bekle
  });

    return {
      loading,
      chartOptionsComputed,
      icons: {
        mdiChartBar
      }
    }
  },
}
</script>

<style lang="scss">
#sessions-chart {
  .apexcharts-series {
    &[seriesName='Earning'] {
      transform: scaleY(0.965);
    }
    &[seriesName='Expense'] {
      transform: scaleY(1.035);
    }
  }
}
.user-profile-menu-content {
  .v-list-item {
    min-height: 2.5rem !important;
  }
}


</style>
